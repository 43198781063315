<template>
	<div class="relative">
		<FerrisWheelEllipses :enable-transitions="isMounted">
			<template #orbit>
				<div class="badges">
					<img
						v-for="{imgSrc, name} in badges"
						:key="name"
						:src="imgSrc"
						:alt="name"
						height="55"
						width="55"
						:class="isMounted ? 'opacity-100 scale-100' : 'opacity-0 scale-0'"
					>
				</div>
			</template>
		</FerrisWheelEllipses>
	</div>
</template>

<script async>
import FerrisWheelEllipses from '@/components/icons/partners/FerrisWheelEllipses.vue'

export default {
	components: {
		FerrisWheelEllipses
	},
	props: {
		badges: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			isMounted: false
		}
	},
	mounted() {
		setTimeout(() => {
			this.isMounted = true
		}, 0)
	}
}
</script>

<style lang="scss">
@media screen and (max-width: 347px) {
  @keyframes badge_rotate {
		0% {
			transform: rotate(0) translateX(125px) rotate(0);
		}
		100% {
			transform: rotate(360deg) translateX(125px) rotate(-360deg);
		}
	}
}
@media (min-width: 348px) and (max-width: 1279px) {
  @keyframes badge_rotate {
		0% {
			transform: rotate(0) translateX(150px) rotate(0);
		}
		100% {
			transform: rotate(360deg) translateX(150px) rotate(-360deg);
		}
	}
}
@media (min-width: 1279px) {
  @keyframes badge_rotate {
		0% {
			transform: rotate(0) translateX(210px) rotate(0);
		}
		100% {
			transform: rotate(360deg) translateX(210px) rotate(-360deg);
		}
	}
}

.badges img {
  animation: badge_rotate 40s infinite linear;
	@apply absolute top-1/2 left-1/2 w-[25%] h-[25%]
	rounded-full border-[16px] border-gray-300/20
	transform transition-all duration-500 ease-in-out
	xl:-mt-[52px] xl:-ml-[52px] -mt-[40px] -ml-[40px] xs:-mt-[36px] xs:-ml-[36px];
}
.badges img:nth-child(1) { // blaze
	animation-delay: 0s;
	@apply delay-[750ms]
}
.badges img:nth-child(2) { // mj freeway
  animation-delay: -6s;
	@apply delay-[800ms]
}

.badges img:nth-child(3) { // greenbits
  animation-delay: -11.7s;
	@apply delay-[850ms]
}

.badges img:nth-child(4) { // treez
  animation-delay: -17.2s;
	@apply delay-[900ms]
}

.badges img:nth-child(5) { // flowhub
  animation-delay: -22.6s;
	@apply delay-[950ms]
}

.badges img:nth-child(6) { // proteus
  animation-delay: -28.4s;
	@apply delay-[1000ms]
}

.badges img:nth-child(7) { // adilas
  animation-delay: -34.25s;
	@apply delay-[1050ms]
}
</style>
