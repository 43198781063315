<template>
	<div class="relative w-full h-full aspect-1">
		<div
			id="outermost"
			:class="[
				hideOuterRings ? 'bg-transparent' : 'bg-gray-100 border outer-box-shadow border-gray-300/50',
				enableTransitions ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
				'transform transition-all duration-1000 ease-in-out delay-0'
			]"
			class="flex items-center justify-center w-full h-full rounded-full "
		>
			<div
				id="outer"
				:class="[
					hideOuterRings ? 'bg-transparent outer-box-shadow' : 'bg-[#fbfcfc] border  border-gray-300/50',
					enableTransitions ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
					'transform transition-all duration-1000 ease-in-out delay-200'
				]"
				class="flex items-center justify-center w-[73%] m-auto rounded-full h-[73%]"
			>
				<div
					id="inner"
					:class="[
						hideOuterRings ? 'bg-transparent' : 'bg-[#fdfefe] inner-box-shadow',
						enableTransitions ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
						'transform transition-all duration-1000 ease-in-out delay-[300ms]'
					]"
					class="relative flex items-center justify-center w-[70%] rounded-full h-[70%]"
				>
					<div
						id="innermost"
						:class="[
							enableTransitions ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
							'transform transition-all duration-1000 ease-in-out delay-[400ms]'
						]"
						class="relative flex items-center justify-center w-[60%] bg-white rounded-full innermost-box-shadow h-[60%]"
					>
						<slot name="center">
							<WwLogo
								:class="[
									enableTransitions ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
									'transform transition-all duration-1000 ease-in-out delay-[900ms]'
								]"
								class="w-[50%] h-[50%]"
							/>
						</slot>
						<div class="absolute w-full h-full rounded-full shadow-xl animate-pulse shadow-purple-500/10" />
					</div>
					<div class="absolute w-full h-full rounded-full shadow-xl animate-pulse shadow-purple-500/10" />
				</div>
			</div>
			<slot name="orbit" />
		</div>
	</div>
</template>

<script async>
import WwLogo from '@/components/multiUse/WwLogo.vue'

export default {
	components: {
		WwLogo
	},
	props: {
		enableTransitions: {
			type: Boolean,
			default: false
		},
		hideOuterRings: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>
.innermost-box-shadow {
	box-shadow: 0px 4.88855px 14.357px rgba(0, 0, 0, 0.05);
}
.inner-box-shadow {
	box-shadow: 0px 5.20911px 46.0568px rgba(0, 0, 0, 0.06);
}
.outer-box-shadow {
	box-shadow: 0px 5.20911px 46.0568px rgba(0, 0, 0, 0.021);
}
</style>
