<template>
	<div
		v-if="!formComplete"
		id="partners"
		class="pt-10 pb-6 bg-gray-100 rounded-lg md:py-10 force-w-screen"
	>
		<div class="grid grid-cols-1 px-4 pb-4 mx-auto md:px-8 lg:px-4 lg:max-w-2xl gap-y-8">
			<div class="flex flex-col space-y-3">
				<h2 class="text-3xl font-bold text-center">
					Integrate your business
				</h2>
				<p class="text-xl leading-relaxed text-center">
					Want to work with our team? We’d love to hear from you. Whether you’re a business specializing in POS, CRM & loyalty, compliance, consulting or something else. Contact us and we will get back to you soon.
				</p>
			</div>
			<form
				class="grid grid-cols-1 gap-x-2 gap-y-6 md:grid-cols-2"
				@submit.prevent="submitForm"
			>
				<div
					v-for="field in form"
					:key="field.label"
					class="text-left"
				>
					<WwTextInput
						v-if="field.id === 'phone'"
						:id="`partners-${field.id}`"
						v-model="field.value"
						:input-type="field.type"
						:required="field.required"
						:value="field.value"
						:autocomplete="field.autocomplete"
						:label="field.label"
						:state="formPhoneInputState"
						input-class="w-full"
						@validity-changed="handlePhoneValidation"
					/>
					<WwTextInput
						v-else
						:id="`partners-${field.id}`"
						v-model="field.value"
						:value="field.value"
						:autocomplete="field.autocomplete"
						:label="field.label"
						:input-type="field.type"
						:required="field.required"
						:state="inputFieldState(field.value, field.type)"
						input-class="w-full"
					/>
				</div>
				<WwSubmitButton
					v-ripple
					class="px-2 mt-4 text-lg font-bold text-white uppercase border-none rounded md:col-span-2 xl:col-span-2 h-11"
					:disabled="!formIsValid"
					:class="formIsValid ? 'bg-green-500 shadow-md hover:shadow-lg md:hover:shadow-lg md:hover:shadow-green-500/50 shadow-green-500/50 md:hover:bg-green-400' : 'bg-gray-300'"
				>
					Submit
				</WwSubmitButton>
			</form>
		</div>
	</div>
</template>

<script async>
import { mapActions, mapMutations } from 'vuex'

import WwSubmitButton from '@/components/UI/buttons/WwSubmitButton.vue'
import WwTextInput from '@/components/UI/WwTextInput.vue'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { EMAIL, TEL, TEXT } from '@/constants/text-inputs/types.js'
import { SUCCESS } from '@/constants/toast/type.js'
import { inputFieldState } from '@/validators/text-inputs.js'

export default {
	components: {
		WwTextInput,
		WwSubmitButton
	},
	data() {
		return {
			formComplete: false,
			form: [
				{ label: 'First Name', value: '', id: 'first_name', required: true, type: TEXT, autocomplete: 'given-name' },
				{ label: 'Last Name', value: '', id: 'last_name', required: true, type: TEXT, autocomplete: 'last-name' },
				{ label: 'Email', value: '', id: 'email', required: true, type: EMAIL, autocomplete: 'email' },
				{ label: 'Phone Number', value: '', id: 'phone', required: true, type: TEL, autocomplete: 'tel' },
				{ label: 'Company Name', value: '', id: 'company', required: true, type: TEXT, autocomplete: 'on' },
				{ label: 'Website', value: '', id: 'website', required: true, type: TEXT, autocomplete: 'url' }
			],
			TEL,
			EMAIL,
			INPUT_STATES,
			phoneValid: false,
			inputFieldState
		}
	},
	computed: {
		formIsValid() {
			return !this.form
				.filter(field => field.required)
				.some(field => inputFieldState(field.value, field.type) === INPUT_STATES.ERROR)
		},
		formPhoneInputState() {
			if (!this.form?.find(field => field.label === 'Phone Number')?.value.length) {
				return INPUT_STATES.ERROR
			}
			return this.phoneValid ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formData() {
			const obj = {}
			const assignField = (field) => { obj[field.id] = field.value }
			this.form.forEach(field => assignField(field))
			return obj
		}
	},
	methods: {
		...mapActions([ 'newPost' ]),
		...mapMutations('toast', [ 'showToast' ]),
		async submitForm () {
			const endpoint = 'Partners/postPartnerForm'

			const response = await this.newPost({
				endpoint,
				params: this.formData,
				store: this.$store
			})
			if (response.data?.success) {
				const clearValue = (field) => {
					field.value = ''
				}
				this.form.forEach(field => clearValue(field))
				this.formComplete = true
				this.showToast({
					primaryText: 'Thanks for reaching out',
					type: SUCCESS
				})
			}
		},
		clearForm () {
			for (const input in this.form) {
				this.form[input].value = ''
			}
			this.form.phone.value = ''
		},
		handlePhoneValidation(payload) {
			this.phoneValid = payload
		}
	}
}
</script>
