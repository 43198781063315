<template>
	<default-layout>
		<div>
			<section class="relative py-8 bg-white">
				<div class="absolute left-0 right-0 text-gray-200 -bottom-px">
					<svg
						preserveAspectRatio="none"
						viewBox="0 0 2000 350"
						xmlns="http://www.w3.org/2000/svg"
					>
						<polygon
							fill="currentColor"
							points="2000 0 2000 350 0 350"
						/>
					</svg>
				</div>

				<div class="relative flex flex-col items-center mx-auto text-center xl:flex-row md:max-w-2xl lg:max-w-4xl xl:max-w-6xl xl:text-left">
					<div class="xl:w-1/2">
						<div class="m-4">
							<h1 class="py-3 text-3xl font-bold">
								Integrate your favorite apps.
							</h1>
							<p class="text-xl">
								{{ copy.favoriteApps }}
							</p>
						</div>
						<a
							href="#partners"
							class="p-2.5 m-4 text-lg font-bold text-white uppercase border-none rounded bg-purple-500"
						>
							Become a Partner
						</a>
					</div>
					<div class="relative flex justify-center w-full px-4 my-12 xl:w-1/2">
						<img
							src="@/assets/partners/partners-hero2.svg"
							alt="Partners Hero"
							height="750"
							width="750"
						>
					</div>
				</div>
			</section>

			<section class="bg-gray-200">
				<div class="p-4 m-auto md:max-w-2xl lg:max-w-4xl xl:max-w-6xl">
					<h2 class="w-full text-3xl font-bold text-left">
						<a>
							Our Partners
						</a>
					</h2>
					<div class="flex flex-wrap justify-between -mx-3">
						<div
							v-for="{ name, imgSrc, type, description} in partners"
							:key="name"
							class="flex w-full py-3 mx-3 lg:w-96 xl:w-80"
						>
							<div class="flex-1 p-4 bg-white rounded-lg shadow-sm md:h-72">
								<div class="flex items-center justify-between">
									<h2 class="text-2xl font-bold">
										{{ name }}
									</h2>

									<div class="w-20">
										<img
											:src="imgSrc"
											alt="Partner"
											height="50"
										>
									</div>
								</div>
								<div class="py-3 text-gray-500">
									<h3 class="py-2 uppercase">
										{{ type }}
									</h3>
									<p class="text-sm">
										{{ description }}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div
						v-if="!formComplete"
					>
						<div class="flex flex-col my-4 bg-white rounded-lg lg:flex-row">
							<div
								:style="`backgroundImage: url(${posStockImg})`"
								class="w-full text-white bg-center bg-cover rounded-t-lg lg:rounded-r-none lg:rounded-l-lg"
							>
								<div class="h-full p-8 bg-blue-700 rounded-t-lg lg:rounded-r-none lg:rounded-l-lg bg-opacity-70">
									<h3 class="py-2 text-xl font-bold">
										<a id="partners">
											Become a Partner
										</a>
									</h3>
									<p>
										{{ copy.becomeAPartner }}
									</p>
								</div>
							</div>
							<div class="p-4">
								<form
									class="flex flex-wrap"
									@submit.prevent="submitForm"
								>
									<div
										v-for="field in form"
										:key="field.label"
										class="w-full px-3 my-2 text-left md:w-1/2"
									>
										<WwTextInput
											v-if="field.id === 'phone'"
											:id="`partners-${field.id}`"
											v-model="field.value"
											:input-type="field.type"
											:required="field.required"
											:value="field.value"
											:autocomplete="field.autocomplete"
											:label="field.label"
											:state="formPhoneInputState"
											input-class="w-full"
											@validity-changed="handlePhoneValidation"
										/>
										<WwTextInput
											v-else
											:id="`partners-${field.id}`"
											v-model="field.value"
											:value="field.value"
											:autocomplete="field.autocomplete"
											:label="field.label"
											:input-type="field.type"
											:required="field.required"
											:state="inputFieldState(field.value, field.type)"
											input-class="w-full"
										/>
									</div>
									<div
										class="flex justify-end w-full px-3 pt-4 pb-3"
									>
										<button
											type="submit"
											class="p-1 px-2 text-lg font-bold text-white uppercase border-none rounded"
											:disabled="!formIsValid"
											:class="formIsValid ? 'bg-green-500' : 'bg-green-300 cursor-not-allowed'"
										>
											Submit
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</default-layout>
</template>

<script async>
import { mapActions, mapMutations } from 'vuex'

import WwTextInput from '@/components/UI/WwTextInput.vue'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { EMAIL, TEL, TEXT } from '@/constants/text-inputs/types.js'
import { SUCCESS } from '@/constants/toast/type.js'
import DefaultLayout from '@/layouts/default/Index.vue'
import { inputFieldState } from '@/validators/text-inputs.js'

export default {
	components: {
		DefaultLayout,
		WwTextInput
	},
	data () {
		return {
			partners: [ {
				name: 'Adilas',
				imgSrc: require('@/assets/partners/adilas.jpg'),
				type: 'Point-of-Sale System',
				description: 'Adilas provides a true all in one SaaS solution for the cannabis industry through software, custom web development, operational training and support.'
			}, {
				name: 'Blaze',
				imgSrc: require('@/assets/partners/blaze.jpg'),
				type: 'Point-of-Sale System',
				description: 'Blaze is a cannabis ERP providing best-in-class tools for operators in the seed-to-sale supply chain.'
			}, {
				name: 'Flowhub',
				imgSrc: require('@/assets/partners/flowhub.jpg'),
				type: 'Point-of-Sale System',
				description: 'Flowhub offers a full range of cannabis retail solutions covering point-of-sale and compliance for businesses of all sizes.'
			}, {
				name: 'Greenbits',
				imgSrc: require('@/assets/partners/greenbits.jpg'),
				type: 'Point-of-Sale System',
				description: 'Green Bits is a leading POS system with automatic compliance features and full integration across all business operations.'
			}, {
				name: 'MJ Freeway',
				imgSrc: require('@/assets/partners/mjfreeway.jpg'),
				type: 'Point-of-Sale System',
				description: 'Preventative compliance, inventory visibility, and streamlined operations with MJ Platform\'s powerful and data-rich ERP software.'
			}, {
				name: 'Proteus420',
				imgSrc: require('@/assets/partners/proteus.jpg'),
				type: 'Point-of-Sale System',
				description: 'Proteus is a real time Point of Sale and inventory control system providing complete solutions for the cannabis industry.'
			}, {
				name: 'Treez',
				imgSrc: require('@/assets/partners/treez.jpg'),
				type: 'Point-of-Sale System',
				description: 'Treez is an enterprise-grade, business management platform serving cannabis retailers with POS, payment and grow solutions.'
			} ],
			formComplete: false,
			copy: {
				favoriteApps: 'Where’s Weed works with the best cannabis partners in the industry to help make your business truly successful. From integrating real-time menus & online ordering to increased exposure from varied digital marketing efforts, our partners & clients benefit by leveraging our quality connections.',
				becomeAPartner: 'Want to work with our team? We’d love to hear from you. Whether you’re a business specializing in POS, CRM & loyalty, compliance, consulting or something else, leave a note & our team will be in contact with you soon.'
			},
			form: [
				{ label: 'First Name', value: '', id: 'first_name', required: true, type: TEXT, autocomplete: 'given-name' },
				{ label: 'Last Name', value: '', id: 'last_name', required: true, type: TEXT, autocomplete: 'last-name' },
				{ label: 'Email', value: '', id: 'email', required: true, type: EMAIL, autocomplete: 'email' },
				{ label: 'Phone Number', value: '', id: 'phone', required: true, type: TEL, autocomplete: 'tel' },
				{ label: 'Company Name', value: '', id: 'company', required: true, type: TEXT, autocomplete: 'on' },
				{ label: 'Website', value: '', id: 'website', required: true, type: TEXT, autocomplete: 'url' }
			],
			posStockImg: require('@/assets/pos/pos_stock.jpeg'),
			TEL,
			EMAIL,
			INPUT_STATES,
			phoneValid: false,
			inputFieldState
		}
	},
	metaInfo: {
		title: 'Where\'s Weed Partners | Cannabis Friendly Solutions for POS, CRM & loyalty, compliance and consulting',
		meta: [ {
			name: 'description',
			content: 'Want to work with Where’s Weed? Do you offer cannabis businesses solutions for POS, CRM & loyalty, compliance and consulting '
		}
		]
	},
	computed: {
		formIsValid() {
			return !this.form
				.filter(field => field.required)
				.some(field => inputFieldState(field.value, field.type) === INPUT_STATES.ERROR)
		},
		formPhoneInputState() {
			if (!this.form?.find(field => field.label === 'Phone Number')?.value.length) {
				return INPUT_STATES.ERROR
			}
			return this.phoneValid ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		formData() {
			const obj = {}
			const assignField = (field) => { obj[field.id] = field.value }
			this.form.forEach(field => assignField(field))
			return obj
		}
	},
	methods: {
		...mapActions([ 'newPost' ]),
		...mapMutations('toast', [ 'showToast' ]),
		async submitForm () {
			const endpoint = 'Partners/postPartnerForm'

			const response = await this.newPost({
				endpoint,
				params: this.formData,
				store: this.$store
			})
			if (response.data?.success) {
				const clearValue = (field) => {
					field.value = ''
				}
				this.form.forEach(field => clearValue(field))
				this.formComplete = true
				this.showToast({
					primaryText: 'Thanks for reaching out',
					type: SUCCESS
				})
			}
		},
		clearForm () {
			for (const input in this.form) {
				this.form[input].value = ''
			}
			this.form.phone.value = ''
		},
		handlePhoneValidation(payload) {
			this.phoneValid = payload
		}
	}
}
</script>
