import { render, staticRenderFns } from "./FerrisWheelEllipses.vue?vue&type=template&id=72c4adbc&scoped=true&"
import script from "./FerrisWheelEllipses.vue?vue&type=script&async=true&lang=js&"
export * from "./FerrisWheelEllipses.vue?vue&type=script&async=true&lang=js&"
import style0 from "./FerrisWheelEllipses.vue?vue&type=style&index=0&id=72c4adbc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72c4adbc",
  null
  
)

export default component.exports