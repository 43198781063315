export const PARTNERS = [
	{
		name: 'Adilas',
		value: 'adilas',
		imgSrc: require('@/assets/partners/adilas.jpg'),
		type: 'Point-of-Sale System',
		description: 'Adilas provides a true all in one SaaS solution for the cannabis industry through software, custom web development, operational training and support.',
		featurePage: {
			h1Description: 'Automatically update online live menu inventory in real time.',
			icon: require('@/assets/partners/flowhub/flowhub-icon.svg'),
			partnerLink: 'https://adilas.com/',
			whoIsPartner: 'Flowhub is the essential cannabis retail management platform for dispensaries. Whether you’re running a single store or multiple locations, Flowhub provides compliance, point of sale, inventory management, mobile solutions and data to help you succeed.',
			integration: {
				title: 'Drive more new customers to your store when you streamline order fullfilmment',
				description: 'The integration of two trusted and robust tech solutions helps streamline the order fulfillment process. When a customer finds the perfect cannabis product on our Where’s Weed Menu, their order is sent directly to FlowHub POS, automating online order intake, and eliminating the need to coordinate multiple channels to keep orders current.',
				features: [
					{
						title: 'Forget manual updates!',
						description: 'When a customer places an order on Where’s Weed, the inventory is automatically updated in Flowhub POS. This ensures that the customer receives the product they ordered, and that the store has accurate inventory records.',
						image: require('@/assets/partners/flowhub/inventory.svg')
					},
					{
						title: 'Auto-verified products',
						description: 'Improve product placement on Where’s Weed with auto-verified brands, high quality images, accurate product descriptions and details.',
						image: require('@/assets/partners/flowhub/stopwatch.svg')
					},
					{
						title: 'Increase visibility and shopper confidence',
						description: 'Help new customers find you on the map and give those who prefer to shop online peace of mind in knowing that their items are in-stock and available.',
						image: require('@/assets/partners/flowhub/payment.svg')
					}
				],
				video: 'https://www.youtube.com/embed/255ddHegnUw'
			}
		}
	},
	{
		name: 'Blaze',
		value: 'blaze',
		imgSrc: require('@/assets/partners/blaze.jpg'),
		type: 'Point-of-Sale System',
		description: 'Blaze is a cannabis ERP providing best-in-class tools for operators in the seed-to-sale supply chain.',
		featurePage: {
			h1Description: 'Automatically update online live menu inventory in real time.',
			icon: require('@/assets/partners/flowhub/flowhub-icon.svg'),
			partnerLink: 'https://www.blaze.io/',
			whoIsPartner: 'Flowhub is the essential cannabis retail management platform for dispensaries. Whether you’re running a single store or multiple locations, Flowhub provides compliance, point of sale, inventory management, mobile solutions and data to help you succeed.',
			integration: {
				title: 'Drive more new customers to your store when you streamline order fullfilmment',
				description: 'The integration of two trusted and robust tech solutions helps streamline the order fulfillment process. When a customer finds the perfect cannabis product on our Where’s Weed Menu, their order is sent directly to FlowHub POS, automating online order intake, and eliminating the need to coordinate multiple channels to keep orders current.',
				features: [
					{
						title: 'Forget manual updates!',
						description: 'When a customer places an order on Where’s Weed, the inventory is automatically updated in Flowhub POS. This ensures that the customer receives the product they ordered, and that the store has accurate inventory records.',
						image: require('@/assets/partners/flowhub/inventory.svg')
					},
					{
						title: 'Auto-verified products',
						description: 'Improve product placement on Where’s Weed with auto-verified brands, high quality images, accurate product descriptions and details.',
						image: require('@/assets/partners/flowhub/stopwatch.svg')
					},
					{
						title: 'Increase visibility and shopper confidence',
						description: 'Help new customers find you on the map and give those who prefer to shop online peace of mind in knowing that their items are in-stock and available.',
						image: require('@/assets/partners/flowhub/payment.svg')
					}
				],
				video: 'https://www.youtube.com/embed/255ddHegnUw'
			}
		}
	},
	{
		name: 'Flowhub',
		value: 'flowhub',
		imgSrc: require('@/assets/partners/flowhub.jpg'),
		type: 'Point-of-Sale System',
		description: 'Flowhub offers a full range of cannabis retail solutions covering point-of-sale and compliance for businesses of all sizes.',
		featurePage: {
			h1Description: 'Automatically update online live menu inventory in real time.',
			icon: require('@/assets/partners/flowhub/flowhub-icon.svg'),
			partnerLink: 'https://www.flowhub.com/',
			whoIsPartner: 'Flowhub is the essential cannabis retail management platform for dispensaries. Whether you’re running a single store or multiple locations, Flowhub provides compliance, point of sale, inventory management, mobile solutions and data to help you succeed.',
			integration: {
				title: 'Drive more new customers to your store when you streamline order fullfilmment',
				description: 'The integration of two trusted and robust tech solutions helps streamline the order fulfillment process. When a customer finds the perfect cannabis product on our Where’s Weed Menu, their order is sent directly to FlowHub POS, automating online order intake, and eliminating the need to coordinate multiple channels to keep orders current.',
				features: [
					{
						title: 'Forget manual updates!',
						description: 'When a customer places an order on Where’s Weed, the inventory is automatically updated in Flowhub POS. This ensures that the customer receives the product they ordered, and that the store has accurate inventory records.',
						image: require('@/assets/partners/flowhub/inventory.svg')
					},
					{
						title: 'Auto-verified products',
						description: 'Improve product placement on Where’s Weed with auto-verified brands, high quality images, accurate product descriptions and details.',
						image: require('@/assets/partners/flowhub/stopwatch.svg')
					},
					{
						title: 'Increase visibility and shopper confidence',
						description: 'Help new customers find you on the map and give those who prefer to shop online peace of mind in knowing that their items are in-stock and available.',
						image: require('@/assets/partners/flowhub/payment.svg')
					}
				],
				video: 'https://www.youtube.com/embed/255ddHegnUw'
			}
		}
	},
	{
		name: 'Greenbits',
		value: 'greenbits',
		imgSrc: require('@/assets/partners/greenbits.jpg'),
		type: 'Point-of-Sale System',
		description: 'Green Bits is a leading POS system with automatic compliance features and full integration across all business operations.',
		featurePage: {
			h1Description: 'Automatically update online live menu inventory in real time.',
			icon: require('@/assets/partners/flowhub/flowhub-icon.svg'),
			partnerLink: 'https://www.greenbits.com/',
			whoIsPartner: 'Flowhub is the essential cannabis retail management platform for dispensaries. Whether you’re running a single store or multiple locations, Flowhub provides compliance, point of sale, inventory management, mobile solutions and data to help you succeed.',
			integration: {
				title: 'Drive more new customers to your store when you streamline order fullfilmment',
				description: 'The integration of two trusted and robust tech solutions helps streamline the order fulfillment process. When a customer finds the perfect cannabis product on our Where’s Weed Menu, their order is sent directly to FlowHub POS, automating online order intake, and eliminating the need to coordinate multiple channels to keep orders current.',
				features: [
					{
						title: 'Forget manual updates!',
						description: 'When a customer places an order on Where’s Weed, the inventory is automatically updated in Flowhub POS. This ensures that the customer receives the product they ordered, and that the store has accurate inventory records.',
						image: require('@/assets/partners/flowhub/inventory.svg')
					},
					{
						title: 'Auto-verified products',
						description: 'Improve product placement on Where’s Weed with auto-verified brands, high quality images, accurate product descriptions and details.',
						image: require('@/assets/partners/flowhub/stopwatch.svg')
					},
					{
						title: 'Increase visibility and shopper confidence',
						description: 'Help new customers find you on the map and give those who prefer to shop online peace of mind in knowing that their items are in-stock and available.',
						image: require('@/assets/partners/flowhub/payment.svg')
					}
				],
				video: 'https://www.youtube.com/embed/255ddHegnUw'
			}
		}
	},
	{
		name: 'MJ Freeway',
		value: 'mj-freeway',
		imgSrc: require('@/assets/partners/mjfreeway.jpg'),
		type: 'Point-of-Sale System',
		description: 'Preventative compliance, inventory visibility, and streamlined operations with MJ Platform\'s powerful and data-rich ERP software.',
		featurePage: {
			h1Description: 'Automatically update online live menu inventory in real time.',
			icon: require('@/assets/partners/flowhub/flowhub-icon.svg'),
			partnerLink: 'https://www.mjfreeway.com/',
			whoIsPartner: 'Flowhub is the essential cannabis retail management platform for dispensaries. Whether you’re running a single store or multiple locations, Flowhub provides compliance, point of sale, inventory management, mobile solutions and data to help you succeed.',
			integration: {
				title: 'Drive more new customers to your store when you streamline order fullfilmment',
				description: 'The integration of two trusted and robust tech solutions helps streamline the order fulfillment process. When a customer finds the perfect cannabis product on our Where’s Weed Menu, their order is sent directly to FlowHub POS, automating online order intake, and eliminating the need to coordinate multiple channels to keep orders current.',
				features: [
					{
						title: 'Forget manual updates!',
						description: 'When a customer places an order on Where’s Weed, the inventory is automatically updated in Flowhub POS. This ensures that the customer receives the product they ordered, and that the store has accurate inventory records.',
						image: require('@/assets/partners/flowhub/inventory.svg')
					},
					{
						title: 'Auto-verified products',
						description: 'Improve product placement on Where’s Weed with auto-verified brands, high quality images, accurate product descriptions and details.',
						image: require('@/assets/partners/flowhub/stopwatch.svg')
					},
					{
						title: 'Increase visibility and shopper confidence',
						description: 'Help new customers find you on the map and give those who prefer to shop online peace of mind in knowing that their items are in-stock and available.',
						image: require('@/assets/partners/flowhub/payment.svg')
					}
				],
				video: 'https://www.youtube.com/embed/255ddHegnUw'
			}
		}
	},
	{
		name: 'Proteus420',
		value: 'proteus420',
		imgSrc: require('@/assets/partners/proteus.jpg'),
		type: 'Point-of-Sale System',
		description: 'Proteus is a real time Point of Sale and inventory control system providing complete solutions for the cannabis industry.',
		featurePage: {
			h1Description: 'Automatically update online live menu inventory in real time.',
			icon: require('@/assets/partners/flowhub/flowhub-icon.svg'),
			partnerLink: 'https://www.proteus420.com/',
			whoIsPartner: 'Flowhub is the essential cannabis retail management platform for dispensaries. Whether you’re running a single store or multiple locations, Flowhub provides compliance, point of sale, inventory management, mobile solutions and data to help you succeed.',
			integration: {
				title: 'Drive more new customers to your store when you streamline order fullfilmment',
				description: 'The integration of two trusted and robust tech solutions helps streamline the order fulfillment process. When a customer finds the perfect cannabis product on our Where’s Weed Menu, their order is sent directly to FlowHub POS, automating online order intake, and eliminating the need to coordinate multiple channels to keep orders current.',
				features: [
					{
						title: 'Forget manual updates!',
						description: 'When a customer places an order on Where’s Weed, the inventory is automatically updated in Flowhub POS. This ensures that the customer receives the product they ordered, and that the store has accurate inventory records.',
						image: require('@/assets/partners/flowhub/inventory.svg')
					},
					{
						title: 'Auto-verified products',
						description: 'Improve product placement on Where’s Weed with auto-verified brands, high quality images, accurate product descriptions and details.',
						image: require('@/assets/partners/flowhub/stopwatch.svg')
					},
					{
						title: 'Increase visibility and shopper confidence',
						description: 'Help new customers find you on the map and give those who prefer to shop online peace of mind in knowing that their items are in-stock and available.',
						image: require('@/assets/partners/flowhub/payment.svg')
					}
				],
				video: 'https://www.youtube.com/embed/255ddHegnUw'
			}
		}
	},
	{
		name: 'Treez',
		value: 'treez',
		imgSrc: require('@/assets/partners/treez.jpg'),
		type: 'Point-of-Sale System',
		description: 'Treez is an enterprise-grade, business management platform serving cannabis retailers with POS, payment and grow solutions.',
		featurePage: {
			h1Description: 'Automatically update online live menu inventory in real time.',
			icon: require('@/assets/partners/flowhub/flowhub-icon.svg'),
			partnerLink: 'https://www.treez.io/',
			whoIsPartner: 'Flowhub is the essential cannabis retail management platform for dispensaries. Whether you’re running a single store or multiple locations, Flowhub provides compliance, point of sale, inventory management, mobile solutions and data to help you succeed.',
			integration: {
				title: 'Drive more new customers to your store when you streamline order fullfilmment',
				description: 'The integration of two trusted and robust tech solutions helps streamline the order fulfillment process. When a customer finds the perfect cannabis product on our Where’s Weed Menu, their order is sent directly to FlowHub POS, automating online order intake, and eliminating the need to coordinate multiple channels to keep orders current.',
				features: [
					{
						title: 'Forget manual updates!',
						description: 'When a customer places an order on Where’s Weed, the inventory is automatically updated in Flowhub POS. This ensures that the customer receives the product they ordered, and that the store has accurate inventory records.',
						image: require('@/assets/partners/flowhub/inventory.svg')
					},
					{
						title: 'Auto-verified products',
						description: 'Improve product placement on Where’s Weed with auto-verified brands, high quality images, accurate product descriptions and details.',
						image: require('@/assets/partners/flowhub/stopwatch.svg')
					},
					{
						title: 'Increase visibility and shopper confidence',
						description: 'Help new customers find you on the map and give those who prefer to shop online peace of mind in knowing that their items are in-stock and available.',
						image: require('@/assets/partners/flowhub/payment.svg')
					}
				],
				video: 'https://www.youtube.com/embed/255ddHegnUw'
			}
		}
	}
]

export default PARTNERS
