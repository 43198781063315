<template>
	<div class="relative w-full h-full overflow-hidden">
		<div class="absolute top-0 w-2/3 transform scale-125 -left-7">
			<FerrisWheelEllipses
				:enable-transitions="isMounted"
				hide-outer-rings
			/>
		</div>
		<div class="absolute top-0 w-2/3 transform scale-125 -right-7">
			<FerrisWheelEllipses
				:enable-transitions="isMounted"
				hide-outer-rings
			>
				<template #center>
					<img
						:src="partnerIcon"
						:alt="partnerName"
						height="55"
						width="55"
						:class="[
							isMounted ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
							'transform transition-all duration-1000 ease-in-out delay-[900ms]'
						]"
						class="w-[50%] h-[50%]"
					>
				</template>
			</FerrisWheelEllipses>
		</div>
	</div>
</template>

<script async>
import FerrisWheelEllipses from '@/components/icons/partners/FerrisWheelEllipses.vue'

export default {
	components: {
		FerrisWheelEllipses
	},
	props: {
		partnerIcon: {
			type: String,
			required: true
		},
		partnerName: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isMounted: false
		}
	},
	mounted() {
		setTimeout(() => {
			this.isMounted = true
		}, 0)
	}
}
</script>

<style lang="scss">

</style>
