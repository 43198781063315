<template>
	<DefaultLayout>
		<div>
			<section class="bg-gray-100 md:pb-8">
				<div class="grid items-center grid-cols-1 px-4 pb-8 mx-auto text-center lg:px-8 xl:px-4 md:grid-cols-2 xl:flex-row xl:max-w-6xl md:text-left">
					<h1 class="order-first pt-4 pb-3 text-4xl font-bold text-left md:hidden">
						Integrate your apps with Where's Weed
					</h1>
					<div class="grid self-start order-last grid-cols-1 gap-4 pt-4 md:pt-10 xl:pt-16">
						<h1 class="hidden text-4xl font-bold md:block">
							Integrate your apps with Where's Weed
						</h1>
						<p class="order-last text-lg leading-relaxed text-left text-black md:max-w-md md:text-xl md:order-none md:mx-0">
							{{ copy.favoriteApps }}
						</p>
						<div class="flex items-center justify-center pb-6 md:pt-3 md:mx-0 md:justify-start">
							<a
								v-ripple
								href="#partners"
								class="flex justify-center w-full h-12 px-10 text-sm font-semibold text-center text-white uppercase transition-all duration-150 ease-in bg-green-500 border-none rounded shadow-md lg:w-auto md:justify-start md:hover:shadow-lg md:hover:shadow-green-500/50 shadow-green-500/50 md:hover:bg-green-400"
							>
								<span class="m-auto">
									Become a Partner
								</span>
							</a>
						</div>
					</div>
					<div class="order-first md:order-last relative flex justify-center xl:p-12 my-12 xl:my-0 h-72 sm:h-80 xl:h-[525px]">
						<PartnersFerrisWheel :badges="PARTNERS" />
					</div>
				</div>
			</section>

			<section class="px-4 bg-white">
				<div class="py-8 m-auto xl:pt-10 md:max-w-none lg:px-4 xl:max-w-6xl">
					<h2 class="w-full pb-6 text-3xl font-bold text-left">
						Our partners
					</h2>
					<div class="grid grid-cols-1 gap-10 pb-8 md:grid-cols-2">
						<PartnersCard
							v-for="partner in PARTNERS"
							:key="partner.name"
							:partner="partner"
						/>
					</div>
				</div>
				<PartnersForm />
			</section>
		</div>
	</DefaultLayout>
</template>

<script async>
import PARTNERS from '@/constants/partners/partner-list.js'
import PartnersCard from '@/views/partners/components/PartnersCard.vue'
import PartnersFerrisWheel from '@/views/partners/components/PartnersFerrisWheel.vue'
import PartnersForm from '@/views/partners/components/PartnersForm.vue'

export default {
	components: {
		DefaultLayout: () => import('@/layouts/default/Index.vue'),
		PartnersFerrisWheel,
		PartnersCard,
		PartnersForm
	},
	data () {
		return {
			PARTNERS,
			copy: {
				favoriteApps: 'Where’s Weed works with the best cannabis partners in the industry to help make your business truly successful. From integrating real-time menus & online ordering to increased exposure from varied digital marketing efforts, our partners & clients benefit by leveraging our quality connections.',
				becomeAPartner: 'Want to work with our team? We’d love to hear from you. Whether you’re a business specializing in POS, CRM & loyalty, compliance, consulting or something else, leave a note & our team will be in contact with you soon.'
			},
			posStockImg: require('@/assets/pos/pos_stock.jpeg')
		}
	},
	metaInfo: {
		title: 'Where\'s Weed Partners | Cannabis Friendly Solutions for POS, CRM & loyalty, compliance and consulting',
		meta: [
			{
				name: 'description',
				content: 'Want to work with Where’s Weed? Do you offer cannabis businesses solutions for POS, CRM & loyalty, compliance and consulting '
			}
		]
	}
}
</script>
