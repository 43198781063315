var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full h-full aspect-1"},[_c('div',{staticClass:"flex items-center justify-center w-full h-full rounded-full",class:[
			_vm.hideOuterRings ? 'bg-transparent' : 'bg-gray-100 border outer-box-shadow border-gray-300/50',
			_vm.enableTransitions ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
			'transform transition-all duration-1000 ease-in-out delay-0'
		],attrs:{"id":"outermost"}},[_c('div',{staticClass:"flex items-center justify-center w-[73%] m-auto rounded-full h-[73%]",class:[
				_vm.hideOuterRings ? 'bg-transparent outer-box-shadow' : 'bg-[#fbfcfc] border  border-gray-300/50',
				_vm.enableTransitions ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
				'transform transition-all duration-1000 ease-in-out delay-200'
			],attrs:{"id":"outer"}},[_c('div',{staticClass:"relative flex items-center justify-center w-[70%] rounded-full h-[70%]",class:[
					_vm.hideOuterRings ? 'bg-transparent' : 'bg-[#fdfefe] inner-box-shadow',
					_vm.enableTransitions ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
					'transform transition-all duration-1000 ease-in-out delay-[300ms]'
				],attrs:{"id":"inner"}},[_c('div',{staticClass:"relative flex items-center justify-center w-[60%] bg-white rounded-full innermost-box-shadow h-[60%]",class:[
						_vm.enableTransitions ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
						'transform transition-all duration-1000 ease-in-out delay-[400ms]'
					],attrs:{"id":"innermost"}},[_vm._t("center",function(){return [_c('WwLogo',{staticClass:"w-[50%] h-[50%]",class:[
								_vm.enableTransitions ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
								'transform transition-all duration-1000 ease-in-out delay-[900ms]'
							]})]}),_vm._v(" "),_c('div',{staticClass:"absolute w-full h-full rounded-full shadow-xl animate-pulse shadow-purple-500/10"})],2),_vm._v(" "),_c('div',{staticClass:"absolute w-full h-full rounded-full shadow-xl animate-pulse shadow-purple-500/10"})])]),_vm._v(" "),_vm._t("orbit")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }