<template>
	<DefaultLayout>
		<div v-if="partner">
			<section class="bg-gray-100 md:pb-8">
				<Breadcrumbs
					:breadcrumbs="breadcrumbs"
					class="max-w-6xl px-4 py-6 mx-auto md:py-6"
				/>
				<div class="grid items-center grid-cols-1 px-4 pb-8 mx-auto text-center lg:px-8 xl:px-4 md:grid-cols-2 xl:flex-row xl:max-w-6xl md:text-left">
					<h1 class="order-first pt-5 pb-3 text-4xl font-bold text-left md:hidden">
						Integrate {{ partnerName }} with Where's Weed
					</h1>
					<div class="grid self-start order-last grid-cols-1 gap-4 md:pt-4 xl:pt-10">
						<h1 class="hidden text-4xl font-bold md:block">
							Integrate {{ partnerName }} with Where's Weed
						</h1>
						<p class="text-xl leading-relaxed text-left text-black md:max-w-md md:order-none md:mx-0">
							{{ pageData.h1Description }}
						</p>
						<div class="flex items-center justify-center pb-6 md:pt-3 md:mx-0 md:justify-start">
							<a
								v-ripple
								href="#partners"
								class="flex justify-center w-full h-12 px-16 text-sm font-semibold text-center text-white uppercase transition-all duration-150 ease-in bg-green-500 border-none rounded shadow-md lg:w-auto md:justify-start md:hover:shadow-lg md:hover:shadow-green-500/50 shadow-green-500/50 md:hover:bg-green-400"
							>
								<span class="m-auto">
									Let's Get Started
								</span>
							</a>
						</div>
					</div>
					<div class="md:min-h-96 order-first md:order-last relative flex justify-center xl:py-12 xs:h-52 h-64 xl:h-[525px]">
						<DoublePartnerFerrisWheel
							:partner-icon="pageData.icon"
							:partner-name="partnerName"
						/>
					</div>
				</div>
				<div class="grid items-start grid-cols-1 gap-4 px-4 pb-8 mx-auto text-center xl:gap-16 lg:px-8 xl:px-4 md:grid-cols-2 xl:flex-row xl:max-w-6xl md:text-left">
					<div
						v-animate-on-scroll="{animation: 'slideInRight'}"
						class="relative grid justify-center w-full grid-cols-1 gap-4 pt-24 text-center"
					>
						<div class="absolute top-0 transform -translate-x-1/2 -translate-y-0 left-1/2">
							<WwLogo class="w-16" />
						</div>
						<div class="text-xl font-bold uppercase">
							Who is Where’s Weed
						</div>
						<div class="text-lg leading-relaxed text-black md:text-xl">
							{{ wwAbout }}
						</div>
						<router-link
							to="/about"
							class="flex px-6 py-2 mx-auto my-4 text-xl text-green-500 uppercase transition-all duration-150 ease-in rounded-full hover:underline hover:bg-gray-200 hover:text-black underline-offset-2"
						>
							LEARN MORE
						</router-link>
					</div>
					<div
						v-animate-on-scroll="{animation: 'slideInLeft'}"
						class="relative grid justify-center w-full grid-cols-1 gap-4 pt-24 text-center"
					>
						<div class="absolute top-0 transform -translate-x-1/2 -translate-y-0 left-1/2">
							<img
								:src="pageData.icon"
								height="85"
								width="85"
								:alt="`${partnerName} logo`"
								class="w-16"
							>
						</div>
						<div class="text-xl font-bold uppercase">
							Who is {{ partnerName }}
						</div>
						<div class="text-lg leading-relaxed text-black md:text-xl">
							{{ pageData.whoIsPartner }}
						</div>
						<a
							:href="pageData.partnerLink"
							class="flex px-6 py-2 mx-auto my-4 text-xl text-green-500 uppercase transition-all duration-150 ease-in rounded-full hover:underline hover:bg-gray-200 hover:text-black underline-offset-2"
						>
							LEARN MORE
						</a>
					</div>
				</div>
			</section>

			<section class="max-w-3xl px-4 mx-auto xl:pt-10 lg:px-4">
				<div class="py-8 m-auto">
					<h2 class="w-full pb-6 text-xl font-bold text-center text-green-500 uppercase">
						{{ partnerName }} & Wheresweed Integration
					</h2>
					<div class="w-full pb-6 text-2xl font-bold leading-relaxed text-center md:text-3xl">
						{{ pageData.integration.title }}
					</div>
					<div class="w-full pb-6 text-xl leading-loose text-center">
						{{ pageData.integration.description }}
					</div>
				</div>
				<div class="border-b border-gray-200">
					<div
						v-for="feature in pageData.integration.features"
						:key="feature.title"
						v-animate-on-scroll
						class="grid items-center content-center w-full grid-cols-1 pb-6 text-left md:pb-16 md:space-x-16 md:flex"
					>
						<img
							:src="feature.image"
							height="85"
							width="85"
							:alt="`${partnerName} feature icon`"
							class="w-1/2 pb-8 mx-auto md:pb-0 md:mx-0 md:self-start md:w-1/4"
						>

						<div class="flex flex-col pb-4 space-y-4 md:pb-0">
							<div class="w-full text-2xl font-bold leading-relaxed">
								{{ feature.title }}
							</div>
							<div class="w-full text-lg leading-relaxed md:text-xl">
								{{ feature.description }}
							</div>
						</div>
					</div>
				</div>
				<div class="pt-8 pb-12">
					<div class="w-full pb-6 text-2xl font-bold text-center md:text-3xl">
						Where’s Weed and {{ partnerName }} in action
					</div>
					<iframe
						v-if="hasVideo"
						width="771"
						height="432"
						:src="pageData.integration.video"
						class="w-full mx-auto md:w-auto aspect-16/9"
						:title="`${partner.name} video`"
					/>
				</div>
				<PartnersForm />
			</section>
		</div>
	</DefaultLayout>
</template>

<script async>
import Breadcrumbs from '@/components/multiUse/Breadcrumbs.vue'
import WwLogo from '@/components/multiUse/WwLogo.vue'
import PARTNERS from '@/constants/partners/partner-list.js'
import DoublePartnerFerrisWheel from '@/views/partners/components/DoublePartnerFerrisWheel.vue'
import PartnersForm from '@/views/partners/components/PartnersForm.vue'

export default {
	components: {
		DefaultLayout: () => import('@/layouts/default/Index.vue'),
		DoublePartnerFerrisWheel,
		PartnersForm,
		WwLogo,
		Breadcrumbs
	},
	data () {
		return {
			PARTNERS,
			wwAbout: 'Where’s Weed is the comprehensive online consumer resource that connects visitors with relevant cannabis businesses across the US and Canada. We help people find dispensaries, delivery services, doctors, branded products and related deals -- plus relevant cannabis news and culture.'
		}
	},
	metaInfo: {
		title: 'Where\'s Weed Partners | Cannabis Friendly Solutions for POS, CRM & loyalty, compliance and consulting',
		meta: [
			{
				name: 'description',
				content: 'Want to work with Where’s Weed? Do you offer cannabis businesses solutions for POS, CRM & loyalty, compliance and consulting '
			}
		]
	},
	computed: {
		pageData() {
			return this.partner?.featurePage
		},
		hasVideo() {
			return this.pageData?.integration?.video
		},
		partnerName() {
			return this.partner?.name
		},
		partner() {
			return PARTNERS.find(partner => partner.value === this.$route.params?.partnerName)
		},
		breadcrumbs() {
			return [
				{ url: '/partners', content: 'Partners' }
			]
		}
	},
	mounted() {
		if (!this.partner) {
			this.$router.push({ name: 'partners' })
		}
	}
}
</script>
