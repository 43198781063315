<template>
	<router-link
		:to="{ name: 'partner', params: { partnerName: partner.value } }"
		class="grid w-full grid-cols-1 gap-4 p-4 text-black transition-all duration-150 ease-in bg-white border border-gray-200 rounded-lg shadow-lg group hover:shadow-xl"
	>
		<div class="mx-auto">
			<img
				:src="partner.imgSrc"
				height="55"
				width="55"
				alt="partner logo"
				class="w-full"
			>
		</div>
		<h2 class="text-4xl font-bold">
			{{ partner.name }}
		</h2>
		<h3 class="text-2xl font-semibold uppercase">
			{{ partner.type }}
		</h3>
		<p class="text-xl">
			{{ partner.description }}
		</p>
		<div class="flex px-6 py-2 mx-auto my-4 text-xl text-green-500 uppercase transition-all duration-150 ease-in rounded-full group-hover:underline group-hover:bg-gray-200 group-hover:text-black underline-offset-2">
			LEARN MORE
		</div>
	</router-link>
</template>

<script async>
export default {
	props: {
		partner: {
			type: Object,
			required: true
		}
	}
}
</script>
